<footer>
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 heading">
                    Contact Us
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" style="margin: 10px 0;">
                    <div class="footer-box">
                        <div>
                            <span><i class="bi bi-geo-alt pd-ic"></i></span>
                        </div>
                        <span class="pd-ic">
                            1501, CASA CELESTE,
                            Lodha Amara, Kolshet Road
                            Thane west, 400607.</span>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" style="margin: 10px 0;">
                    <div class="footer-box">
                        <div>
                            <span><i class="bi bi-envelope-fill pd-ic"></i></span>
                        </div>
                        <span class="pd-ic"> <a href="mailto:business@kaitechnologies.in" target="_blank"
                                rel="noopener noreferrer">
                                business&#64;kaitechnologies.in</a></span>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" style="margin: 10px 0;">
                    <div class="footer-box">
                        <div>
                            <span><i class="bi bi-envelope pd-ic"></i></span>
                        </div>
                        <span class="pd-ic"> <a href="mailto:info@kaitechnologies.in" target="_blank"
                                rel="noopener noreferrer">
                                info&#64;kaitechnologies.in</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container footer-bottom clearfix">
        <div class="row">
            <div class="col-md-6 col-lg-6">
                <div class="copyright">
                    © Copyright <strong><span>KAI Technologies</span></strong>. All Rights Reserved
                </div>
            </div>
            <div class="social-links col-md-6 col-lg-6">
                <a href="https://www.linkedin.com/company/kaitechnologies" target="_blank" class="linkedin"><i
                        class="bi bi-linkedin"></i></a>

                <a href="https://maps.app.goo.gl/mWgtTFLfKnEp3omc6" target="_blank" class="google"><i
                        class="bi bi-google"></i></a>

                <a href="https://www.youtube.com/channel/UC3OjQH-ul7P7wTPlKQE9sGg" target="_blank" class="youtube"><i
                        class="bi bi-youtube"></i></a>
            </div>
        </div>
    </div>
</footer>