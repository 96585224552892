<div class="main-footer">
  <div class="banner container-fluid row">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 row left">
      <div class="head col-xl-12">Let's Innovate with KAI</div>
      <div class="sub-head col-xl-12">Every day, Our experts help
        businesses around the globe accelerate
        digital transformation and build a more resilient,
        sustainable and inclusive future.</div>
    </div>
    <div class="col-sm-12 col-md-12  col-lg-6 col-xl-6 banner-image">
      <img src="./../../../../../assets/image/contact.svg">
    </div>
  </div>
</div>



<div class="container">
  <div class="row">
    <div class="col-lg-6">
      <div class="site-width" id="contact-form">
        <div class="header">
          <h2 class="heading"><strong>Let's work together</strong></h2>
          <p><span>We're collaborating with some of the largest brands in the world, as well as with startups.
              We’d love to learn your needs, vision and explore how we can assist exceeding your goals.</span></p>
        </div>
        <form class="ct-frm" [formGroup]="contactForm" #myForm="ngForm" (ngSubmit)="submit()">
          <div class="p-grid">
            <div class="p-col-12 p-sm-12 p-md-6 p-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter the name" formControlName="name">
                <mat-error *ngIf="contactForm.controls.name.hasError('required')">
                  Name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Enter the email" formControlName="email">
                <mat-error
                  *ngIf="contactForm.controls.email.hasError('email') && !contactForm.controls.email.hasError('required')">
                  Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="contactForm.controls.email.hasError('required')">
                  Name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>comment</mat-label>
                <textarea matInput placeholder="Leave a comment" formControlName="comment"></textarea>
                <mat-error *ngIf="contactForm.controls.comment.hasError('required')">
                  Comment is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="g-recaptcha" data-sitekey="6LflEFcdAAAAAJZPwatOITZL1a1EYYxDxhOiPW-W"></div>
          <button mat-raised-button color="primary" class="btn-comment" type="submit"
            [disabled]="contactForm.invalid">Submit</button>
        </form>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="clearfix right-grp">
        <h2 class="head">Call us / Write to us</h2>
        <div class="tx-al"><span class="head">Business</span>
          <div>
            <i class="bi bi-envelope-fill"></i><a href="mailto:business@kaitechnologies.in" target="_blank"
              rel="noopener noreferrer">
              business&#64;kaitechnologies.in</a>
          </div>
        </div>
        <div class="tx-al"><span class="head">General Information</span>
          <div>
            <i class="bi bi-envelope"></i><a href="mailto:info@kaitechnologies.in" target="_blank"
              rel="noopener noreferrer">
              info&#64;kaitechnologies.in</a>
          </div>
        </div>
        <div class="tx-al"><span class="head">HR & Career</span>
          <div>
            <i class="bi bi-briefcase-fill"></i><a href="mailto:hr@kaitechnologies.in" target="_blank"
              rel="noopener noreferrer">hr&#64;kaitechnologies.in </a>
          </div>
        </div>
        <div class="tx-al"><span class="head">Address</span>
          <div>
            <i class="bi bi-geo-alt-fill"></i>
            1501, CASA CELESTE,
            Lodha Amara, Kolshet Road
            Thane west, 400607.
          </div>
        </div>
      </div>
    </div>
  </div>