import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  isShown: boolean;

  get data() {
    return this.isShown;
  }

  set data(value: boolean) {
    this.isShown = value;
  }
}
