import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nodejs-dev',
  templateUrl: './nodejs-dev.component.html',
  styleUrls: ['./nodejs-dev.component.scss']
})
export class NodejsDevComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
