import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './pages/services/services.component';
import { CareersComponent } from './pages/careers/careers/careers.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ProductComponent } from './pages/product/product.component';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'product', component: ProductComponent
  },
  {
    path: 'services', component: ServicesComponent
  },
  {
    path: 'contact-us', component: ContactUsComponent
  },
  {
    path: 'solutions', component: ServicesComponent
  },
  {
    path: 'careers', component: CareersComponent
  },
  {
    path: 'portfolio', component: PortfolioComponent
  },
  {
    path: 'about', component: AboutUsComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class WebRoutingModule { }
