import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-hired',
  templateUrl: './get-hired.component.html',
  styleUrls: ['./get-hired.component.scss']
})
export class GetHiredComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
