<div class="service-main service-banner">
    <div class="container">
        <div class="row">
            <div class="head">
                Services
            </div>
            <div class="sub-head">
                Discover how KAI services and solutions can help your enterprise turn digital challenges into business
                success.
            </div>
        </div>
    </div>
</div>
<div class="services">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-icon">
                            <img src="./assets/image/product.svg" height="38px" alt="Product Development">
                            <h3 class="card-title">Product Development</h3>
                        </div>
                        <p class="card-text">
                            Development of software solutions for startup needs speed, agility and deep
                            architectural know-how from their partners. We bring that flexibility & competency to
                            rapidly realize market-potential and growth.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-icon">
                            <img src="./assets/image/ai.svg" height="48px" alt="Machine Learning">
                            <h3 class="card-title">AI & Machine Learning</h3>
                        </div>
                        <p class="card-text">
                            Organizations need a strong foundation in data to be able to transform their businesses
                            and dynamically tailor their products, offerings and operations for their different
                            constituents. We provide services.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-icon">
                            <img src="./assets/image/cloud.svg" height="30px" alt="Cloud Ops">
                            <h3 class="card-title">Cloud Ops</h3>
                        </div>
                        <p class="card-text">
                            As cloud computing becomes critical for business and IT, we support business through all
                            phases of their cloud adoption journey, Using reinforcing strengths in the areas of
                            cloud services, and security services.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>