<div class="main-product">
  <div class="banner">
  </div>

  <div class="container product-suite">
    <div class="head">KAI Product Suite</div>
    <div class="sub-head">
      A complete solution for your restaurant’s technology, operations and marketing needs. All within an integrated
      framework.</div>
  </div>

  <div class="container">
    <div class="row product">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="product-image">
          <img src="./../../../../../assets/image/mobile-pos.png">
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 product-info">
        <div>
          <div class="product-head">WAVE BILL Mobile POS</div>
          <div class="product-featue">
            <li>Available on Android, iOS</li>
            <li>Minimal setup</li>
            <li>Multiple integrations</li>
            <li>Cost-effective technology</li>
          </div>
          <div class="know-more">
            <a routerLink="">Know More
              <i class="bi bi-arrow-right-short"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row product row-reverse">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 product-info">
        <div>
        <div class="product-head">WAVE BILL Desktop POS</div>
        <div class="product-featue">
          <li>Available on Windows & Linux</li>
          <li>Track Inventory on Go</li>
          <li>Whatsapp or Print A4, A5, 3 Inch Format</li>
          <li>Customize Bills as your business needs</li>
        </div>
        <div class="know-more">
          <a routerLink="">Know More
            <i class="bi bi-arrow-right-short"></i>
          </a>
        </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="product-image">
          <img src="./../../../../../assets/image/desktop-pos.png">
        </div>
      </div>
    </div>
    <div class="row  product">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="product-image">
          <img src="./../../../../../assets/image/online-shopping.png">
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 product-info">
        <div>
        <div class="product-head">KAI-Biz Online Ordering </div>
        <div class="product-featue">
          <li>Shopping cart</li>
          <li>Promotion and discount code tools</li>
          <li>Secure and fast checkout</li>
          <li>Reporting tools</li>
          <li>Multiple payment options</li>
        </div>
        <div class="know-more">
          <a routerLink="">Know More
            <i class="bi bi-arrow-right-short"></i>
          </a>
        </div>
      </div>
      </div>
    </div>
  </div>