import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  router: string;
  // tslint:disable-next-line: variable-name
  constructor() {
  }

  ngOnInit(): void {

  }

}
