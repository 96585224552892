
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { WebRoutingModule } from './web-routing.module';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './pages/services/services.component';
import { GetHiredComponent } from './pages/careers/pages/get-hired/get-hired.component';
import { FreeLancingComponent } from './pages/careers/pages/free-lancing/free-lancing/free-lancing.component';
import { InternshipComponent } from './pages/careers/pages/internship/internship/internship.component';
import { RemoteWorkComponent } from './pages/careers/pages/remote-work/remote-work/remote-work.component';
import { WebappDevComponent } from './pages/services/pages/webapp-dev/webapp-dev/webapp-dev.component';
import { NodejsDevComponent } from './pages/services/pages/nodejs-dev/nodejs-dev/nodejs-dev.component';
import { AngularDevComponent } from './pages/services/pages/angular-dev/angular-dev/angular-dev.component';
import { EcommerceDevComponent } from './pages/services/pages/ecommerce-dev/ecommerce-dev/ecommerce-dev.component';
import { ReactjsDevComponent } from './pages/services/pages/reactjs-dev/reactjs-dev/reactjs-dev.component';
import { UiUxDevComponent } from './pages/services/pages/ui_ux-dev/ui-ux-dev/ui-ux-dev.component';
import { CareersComponent } from './pages/careers/careers/careers.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProductComponent } from './pages/product/product.component';
import { FirestoreModule } from '@angular/fire/firestore';


@NgModule({
  declarations: [
    ContactUsComponent,
    HomeComponent,
    ServicesComponent,
    AboutUsComponent,
    PortfolioComponent,
    GetHiredComponent,
    FreeLancingComponent,
    InternshipComponent,
    RemoteWorkComponent,
    WebappDevComponent,
    NodejsDevComponent,
    AngularDevComponent,
    EcommerceDevComponent,
    ReactjsDevComponent,
    UiUxDevComponent,
    CareersComponent,
    ProductComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    WebRoutingModule,
    FirestoreModule
  ],
  exports: [
    AboutUsComponent,
    ServicesComponent,
    PortfolioComponent
  ]
})
export class WebModule { }
