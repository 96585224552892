<div class="main">
    <div class="banner-image">
        <div class="main-banner">
            <div class="head">Digital Transformation, Technology Innovation
            </div>
            <div class="sub-head">
                <div class="text">Digital-First</div>
                <div class="text">|</div>
                <div class="text">Customer-Centric</div>
            </div>
           
        </div>
    </div>
    <div class="banner-btn">
        <a routerLink="/app/product" class="product-btn">
            <span>View Products</span>
            <svg width="13px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </a></div>
</div>

<div class="container home">
    <div class="row align-center">
        <div class="main-text">
            PURPOSE LED INNOVATIVE SOLUTIONS WITH SCALE AND SPEED
        </div>
        <div>
            Our focus on providing the most innovative and best-in-class digital and data solutions, ensures that our
            clients can focus on maximizing business impact. </div>
    </div>
</div>

<div class="container expertise">
    <div class="row">
        <div class="main-text">
            EXPERTISE
        </div>
        <div>
            Industry solutions
        </div>
    </div>
    <div class="solutions">
        <div class="item1">
            <p>
                <img class="item1" src="./../../../../../assets/image/retail.png">
            </p>
            <h3>Retail</h3>
        </div>
        <div class="item2">
            <p>
                <img class="item2" src="./../../../../../assets/image/banking.png">
            </p>
            <h3>Banking</h3>
        </div>
        <div class="item3">
            <p>
                <img class="item3" src="./../../../../../assets/image/ecommerce.png">
            </p>
            <h3>E-Commerce</h3>
        </div>
        <div class="item4">
            <p>
                <img class="item4" src="./../../../../../assets/image/health.png">
            </p>
            <h3>Healthcare</h3>
        </div>
        <div class="item5">
            <p>
                <img class="item5" src="./../../../../../assets/image/transport.png">
            </p>
            <h3>Transportation</h3>
        </div>
    </div>
</div>