<div id="banner-aboutus" class="content-wrapper">
  <div class="banner-overlay">
    <div class="center-width">
      <div class="banner-content">
        <div class="row">
          <div class="col-xs-12 li">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="tx-al">
                <div class="h2">Our Work</div>
                <div class="banner-counter row">
                  <div class="li col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div>15+</div>
                    <span>Projects</span>
                  </div>
                  <div class="li col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div>10+</div>
                    <span>Clients</span>
                  </div>
                  <div class="li col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div>20+</div>
                    <span>Teams</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h1><strong>Our Expertise</strong></h1>
        <div class="">
          <p>
            <span>We provide comprehensive services to build a digital solutions
              and manage complete product lifestyle</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-8 col-lg-8">
      <h2><strong>Focus</strong></h2>
      <div class="row pad-bt-20">
        <div class="col-md-6">
          <img src="./assets/image/mobility.svg" alt="mobility" class="img-logo"><span class="list1">Mobility</span>
        </div>
        <div class="col-md-6"><img src="./assets/image/web.svg" alt="web" class="img-logo"><span
            class="list1">Web</span>
        </div>
        <div class="col-md-6"><img src="./assets/image/cloud2.svg" alt="cloud" class="img-logo"><span
            class="list1">Cloud</span>
        </div>
        <div class="col-md-6">
          <img src="./assets/image/uiux.svg" alt="uiux" class="img-logo"><span class="list1">Ui/Ux</span>
        </div>
        <div class="col-md-6">
          <img src="./assets/image/devops1.svg" alt="devops" class="img-logo"><span class="list1">Devops</span>
        </div>
        <div class="col-md-6">
          <img src="./assets/image/testing.svg" alt="testing" class="img-logo"><span class="list1">Testing</span>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4">
      <h2><strong>Services offerings</strong></h2>
      <ul>
        <li>Product Discovery</li>
        <li>User Experience Design</li>
        <li>Product Development</li>
        <li>Offshore Development Center</li>
        <li>Team Augmentation</li>
        <li>Independent Testing</li>
        <li>Deployment, Devops & Integrations</li>
      </ul>
    </div>
  </div>
</div>