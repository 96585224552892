<div class="careers">
    <div class="banner-overlay">
        <div id="banner-careers" class="content-wrapper">
            <div class="banner-overlay">
                <div class="banner-content">
                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="sd-img sd-img-ban">
                            </div>
                        </div>
                        <div class="col-md-8 col-lg-8">
                            <div class="d1">
                                <h1>Build your dream job here</h1>
                            </div>
                            <div class="h1">
                                Careers
                            </div>
                            <div>
                                <h2>More than a job… it’s an adventure in innovation</h2>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div>
                    <p class="h3">Let's Grow Together</p>
                    <p class="h5"><span class="quote">&#8220;</span>We welcome you as a part of our KAI family
                        and
                        value each and every
                        opinion and idea equally. Here, in KAI, you are free to learn, innovate and grow.<span
                            class="quote">&#8221;</span></p>
                </div>
                <div>
                    <p><span id="head"> Working With Us - Benifits and Opportunities</span>
                    </p>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="card-icon">
                                    <span><img src="./assets/image/challenge.svg" height="150px"
                                            alt="Challenges & Opportunity"></span>
                                </div>
                                <div class="pd-tx">
                                    <span>
                                        <h3 class="card-title">Challenges & Opportunities</h3>
                                    </span>
                                    <span class="card-text">
                                        Grow your career in a multitude of ways - with KAI Technologies. As we expand,
                                        you’ll have opportunities to work with teams across the globe.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-icon">
                                        <span><img src="./assets/image/learning.svg" height="150px"
                                                alt="Learning and Developement"></span>

                                    </div>
                                    <div class="pd-tx">
                                        <span>
                                            <h3 class="card-title">Learning & Development</h3>
                                        </span>
                                        <span class="card-text">
                                            Annual career conversations, learning and development opportunities with
                                            KAI
                                            Technologies, Growth Track: All People Managers, LinkedIn Learning:
                                            Unconscious
                                            Bias, Inclusive Culture, Growth Mindset.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="card-icon">
                                            <span><img src="./assets/image/optimistic.svg" height="150px"
                                                    alt="Optimistic Approach"></span>

                                        </div>
                                        <div class="pd-tx">
                                            <span>
                                                <h3 class="card-title">Optimistic Approach</h3>
                                            </span>
                                            <span class="card-text">
                                                We Believe the developement is done in an optimistic point of view.
                                                Hence, we
                                                encourage our each team member to have an optimistic approach to every
                                                solution.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="card-icon">
                                                <span> <img src="./assets/image/joyful.svg" height="150px"
                                                        alt="Joyfull Environment"></span>
                                            </div>
                                            <div class="pd-tx">
                                                <span>
                                                    <h3 class="card-title">Joyfull Environment</h3>
                                                </span>
                                                <span class="card-text">
                                                    The secret of success of every organization is the happy and healthy
                                                    environment with the company and we follow the strategy.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>