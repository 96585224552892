// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'kai-web-222e5',
    appId: '1:1007491113017:web:d7b91e18ee274efcf0ade8',
    databaseURL: 'https://kai-web-222e5-default-rtdb.firebaseio.com',
    storageBucket: 'kai-web-222e5.appspot.com',
    locationId: 'asia-east2',
    apiKey: 'AIzaSyCeNF6XUtpyq8T1SJXejWlRqeGh8KLWOC4',
    authDomain: 'kai-web-222e5.firebaseapp.com',
    messagingSenderId: '1007491113017',
    measurementId: 'G-NS9VYTNQ4T',
  },
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCeNF6XUtpyq8T1SJXejWlRqeGh8KLWOC4",
    authDomain: "kai-web-222e5.firebaseapp.com",
    projectId: "kai-web-222e5",
    storageBucket: "kai-web-222e5.appspot.com",
    messagingSenderId: "1007491113017",
    appId: "1:1007491113017:web:d7b91e18ee274efcf0ade8",
    measurementId: "G-NS9VYTNQ4T"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
