import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Firestore, addDoc, collection } from '@angular/fire/firestore';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private matSnackBar: MatSnackBar,
    private angularFirestore: Firestore
  ) { }
  @ViewChild('myForm') myForm;
  contactForm: UntypedFormGroup;
  downloadURL;
  snapshot: Observable<unknown>;
  basePath = '/images';
  downloadableURL = '';
  files;
  progressValue: Observable<number>;
  task: any;
  imageChangedEvent: any;
  croppedImage: string;

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', Validators.required],
    });
  }

  async submit() {

    const docRef = await addDoc(collection(this.angularFirestore, 'contact'), this.contactForm.getRawValue());
    this.contactForm.reset();
    this.myForm.resetForm();
    this.viewSuccess();
  }

  viewSuccess() {
    this.matSnackBar.open('Thank you for contacting us.', 'OK', {
      duration: 5000,
    });
  }

}

